@import "Styles/CustomBootstrap.scss";

.vs-rounded-1{
  border-radius: 0.75em !important;
}

@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";

.cursor-pointer{
  cursor: pointer;
}

// Spinner de loading.io/css

.lds-spinner {
  color: #ffffff;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: theme-color("primary");
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// fin del spinner


// El comportamiento del sidebar y del .content-wrapper debe ser ajustado antes
// de ser renderizados.
// Los estilos visuales se ajustan en la hoja de estilos que esta
// junto al componente del sidebar.

.ssidebar{
  top: 0;
  bottom: 0;
  z-index: 1021;
  position: fixed;
}

/* .navbar{
  height: 74px;
} */

.ssidebar.active {
  width: 280px;
  left: 0;
}

.ssidebar.collapsed{
  left:-280px;
}

@include media-breakpoint-up(md) {
  .sidebar-controller.active .content-wrapper, .sidebar-controller.active .navbar{
      margin-left: 280px;
  }
}

.sidebar-controller .content-wrapper, .sidebar-controller .navbar{
  -webkit-transition: margin-left 0.3s ease-in-out;
  -moz-transition: margin-left 0.3s ease-in-out;
  -ms-transition: margin-left 0.3s ease-in-out;
  -o-transition: margin-left 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out;
}

.sidebar-controller .ssidebar{
  -webkit-transition: left 0.3s ease-in-out;
  -moz-transition: left 0.3s ease-in-out;
  -ms-transition: left 0.3s ease-in-out;
  -o-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;
}