.v-btnotify-container {
    z-index: 1051;
}

.v-btnotify-container ul, .v-btnotify-container li{
    list-style-type: none;
    list-style: none;   
}

.v-btnotify-container .close-button {
    cursor: pointer;
}

