@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Aca se pueden sobreescribir los colores por defecto
// la variable de abajo es un ejemplo
$custom-theme-colors: (
  "primary": #046aa5,
  "secondary": #E5E5E5
);

// Sobreescribir la variale $theme-colors original de bootstrap
// con $custom-theme-colors
$theme-colors: map-merge(
  $theme-colors,
  $custom-theme-colors
);